import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import { makeStyles } from '@material-ui/core/styles';
import SEO from '../components/seo';
import { injectIntl } from 'gatsby-plugin-intl';
import HeroSlider from '../components/Sliders/HeroSlider';
import Header from '../components/Header';
import HeroCard from '../components/Cards/HeroCard';
import backgroundImage from '../images/Backgrounds/redlinebackground-min.png';
import ReviewCardSlider from '../components/Sliders/ReviewCardSlider';
import { shape, func } from 'prop-types';
import NewsCardSlider from '../components/Sliders/NewsCardSlider';

const useStyles = makeStyles(theme => ({
  galleryContiner: {
    width: '60%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  newsContainer: {
    marginTop: 60,
    marginBottom: 80,
  },
  restContainer: {
    background: `url(${backgroundImage}) 50% 0 no-repeat`,
    backgroundSize: 'cover',
    padding: 75,
    paddingTop: 20,
    [theme.breakpoints.down('lg')]: {
      padding: 50,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '75px 10px',
      paddingTop: 0,
    },
  },
  reviewsContainer: {
    marginBottom: 60,
  },
  subtitle: {
    marginLeft: 20,
    padding: '75px 0 50px 0!important',
    [theme.breakpoints.down('lg')]: {
      padding: '50px 0 30px 0',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '50px 0 10px 0',
    },
  },
}));

const RootIndex = ({ intl, data }) => {
  const { formatMessage } = intl;
  const banners = data.allContentfulHeroBanner.edges;
  const siteTitle = data.site.siteMetadata.title;
  const news = data.allContentfulBlogPost.edges;
  const reviews = data.allContentfulReview.edges;
  const classes = useStyles();
  // This is a fake edit, new content deploy from contentful
  return (
    <Layout>
      <SEO
        title="HeadzUp Sport AB"
        description="Vår mission är att skapa skickligare ishockeyspelare och vår vision är att minska på antalet ovårdade tacklingar och skador"
        keywords={['headzup']}
      />
      <Helmet title={siteTitle} />
      <HeroSlider showThumbs={false} data={banners} />
      <HeroCard />
      <div className={classes.restContainer}>
        <div
          className={classes.reviewsContainer}
          id="reviews"
        >
          <Header className={classes.subtitle}>
            {formatMessage({
              id: 'index.restContainer.subtitle1',
            })}
          </Header>
          <ReviewCardSlider
            cardsData={reviews}
            settings={{
              dots: true,
            }}
          />
        </div>
        <div className={classes.newsContainer}>
          <Header className={classes.subtitle}>
            {formatMessage({
              id: 'index.restContainer.subtitle2',
            })}
          </Header>
          <NewsCardSlider
            cardsData={news}
            settings={{
              dots: true,
            }}
          />
        </div>
      </div>
    </Layout>
  );
};

RootIndex.propTypes = {
  intl: shape({
    formatMessage: func.isRequired,
  }).isRequired,
  data: shape().isRequired,
};

export default injectIntl(RootIndex);

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulBlogPost(
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          title
          introText
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          postType
          linkUrl
          heroImage {
            fluid(
              maxWidth: 350
              maxHeight: 196
              resizingBehavior: SCALE
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulHeroBanner(
      sort: { fields: [publishDate], order: DESC }
      limit: 5
    ) {
      edges {
        node {
          image: image {
            fluid(
              quality: 90
              maxWidth: 1921
              maxHeight: 1002
              resizingBehavior: PAD
              background: "transparent"
            ) {
              ...GatsbyContentfulFluid
            }
          }
          header
          subHeader
          videoSrc
        }
      }
    }
    allContentfulReview(
      filter: { visibleIn: { eq: "Home" } }
      sort: { fields: createdAt, order: DESC }
    ) {
      edges {
        node {
          image {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          name
          description
          comment {
            comment
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
