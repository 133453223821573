import React from 'react';
import { makeStyles } from '@material-ui/core/styles/';
import BackgroundImage from 'gatsby-background-image';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import Grid from '@material-ui/core/Grid';
import FONT from '../../constants/fonts';
import VIDEOS from '../../constants/videos';
import COLORS from '../../constants/colors';
import { array } from 'prop-types';
import DefaultButton from '../Buttons/DefaultButton';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import { Link } from 'gatsby';

//TODO: Decide setup for herobanne title and language localization
//TODO: Check 4K styles and when final styles done implement
const useStyles = makeStyles(theme => ({
  heroImage: {
    height: '90vh',
    backgroundColor: 'black',
    marginBottom: -1,
    [theme.breakpoints.down('sm')]: {
      height: '66vh',
    },
  },
  videoContainer: {
    height: '90vh',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '66vh',
    },
    '&>div': {
      position: 'absolute',
      top: 0,
      left: 0,
    },
  },
  video: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'right',
    [theme.breakpoints.down('xs')]: {
      objectPosition: '70%',
    },
  },
  gridContainer: {
    height: '100%',
    display: 'flex',
    alignContent: 'top',
    alignItems: 'top',
    userSelect: 'none',
    paddingTop: 180,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 80,
    },
  },
  subHeaderLeftInner: {
    color: 'white',
    maxWidth: 600,
    textAlign: 'left',
    marginLeft: '20%',
    paddingTop: 30,
    '@media (min-width:2500px)': {
      paddingTop: 80,
      fontSize: 22,
    },
    '@media (max-width:1200px)': {
      paddingTop: 20,
    },
    '@media (max-width:600px)': {
      marginLeft: '10%',
    },
    '@media (max-width:800px)': {
      marginLeft: '10%',
    },
  },
  subHeaderWrapper: {
    '@media (min-width:2500px)': {
      width: '100%',
    },
  },
  headerLeft: {
    fontFamily: FONT.FAMILY.SECONDARY,
    fontSize: '5vw',
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'left',
    lineHeight: '0.86em',
    marginBottom: '0px',
    wordSpacing: '100vw',
    width: 70,
    marginLeft: '20%',
    '@media (max-width:600px)': {
      fontSize: '4vh',
      marginLeft: '10%',
    },
    '@media (max-width:900px)': {
      fontSize: '7vh',
      marginLeft: '10%',
    },
    '@media (max-width:500px)': {
      fontSize: '5vh',
      marginLeft: '10%',
    },
  },
  redHead: {
    fontFamily: FONT.FAMILY.SECONDARY,
    fontSize: '5vw',
    color: COLORS.RED,
    fontWeight: 'bold',
    textAlign: 'left',
    lineHeight: '0.86em',
    marginBottom: '0px',
    wordSpacing: '90vw',
    width: 100,
    marginLeft: '20%',
    '@media (max-width:700px)': {
      fontSize: '5vh',
      marginLeft: '10%',
    },
    '@media (max-width:900px)': {
      fontSize: '7vh',
      marginLeft: '10%',
    },
    '@media (max-width:500px)': {
      fontSize: '5vh',
      marginLeft: '10%',
    },
  },
  buttonConatiner: {
    wordSpacing: 0,
    // width: '76%',
    width: '60%',
    margin: 0,
    padding: 0,
    marginLeft: 20,
    '@media (max-width:700px)': {
      // display: 'none',
      paddingTop: 16,
      width: '94%',
    },
  },
  button: {
    width: '50%',
    maxWidth: 140,
    margin: '26px 0px 0px 0px',
    '@media (max-width:700px)': {
      margin: '22px 0px 0px 0px',
      fontSize: FONT.SIZE.XXXS,
    },
    '@media (max-width:500px)': {
      margin: '25px 0px 10px 0px',
      fontSize: FONT.SIZE.XXXS,
    },
  },
  subText: {
    fontSize: 18,
    lineHeight: 1.2,
    '@media (max-width:700px)': {
      fontSize: 15,
    },
  },
  linkStyle: {
    textDecoration: 'none',
  },
}));

const HeroSlider = ({ data }) => {
  const classes = useStyles();

  const HeaderText = props => {
    return (
      <h1 className={classes.redHead}>
        {props.children.split(' ').map((text, i) => {
          if (i === 1) {
            return (
              /*TODO:Replace key value. */
              <div key={i} className={classes.redHead}>
                {text}
              </div>
            );
          } else {
            return (
              <div key={i} className={classes.headerLeft}>
                {text}
              </div>
            );
          }
        })}
      </h1>
    );
  };

  return (
    <Carousel
      className={classes.root}
      showThumbs={false}
      showStatus={false}
      infiniteLoop
      autoPlay
      interval={5000}
    >
      {/*TODO:Replace index key*/}
      {data.map((item, i) => {
        return item.node.videoSrc ? (
          <div className={classes.videoContainer} key={i}>
            <video
              className={classes.video}
              autoPlay
              loop
              muted
            >
              <source
                src={
                  VIDEOS[parseInt(item.node.videoSrc) + 2]
                }
                type="video/mp4"
              />
            </video>
            <Grid
              container
              className={classes.gridContainer}
            >
              <Grid item xs={6}>
                <HeaderText>{item.node.header}</HeaderText>
              </Grid>
            </Grid>
          </div>
        ) : (
          <BackgroundImage
            key={i}
            fluid={item.node.image.fluid}
            className={classes.heroImage}
            objectFit
          >
            <Grid
              container
              className={classes.gridContainer}
            >
              <>
                <Grid item xs={6}>
                  <div className={classes.div}>
                    <HeaderText>
                      {item.node.header}
                    </HeaderText>
                    {item.node.subHeader && (
                      <div
                        className={
                          classes.subHeaderLeftInner
                        }
                      >
                        <div
                          className={
                            classes.buttonConatiner
                          }
                        >
                          <p className={classes.subText}>
                            {item.node.subHeader}
                          </p>
                          {item.node.subHeader ? (
                            <Link
                              to="/webshop"
                              className={classes.linkStyle}
                            >
                              <DefaultButton
                                className={classes.button}
                                to="/webshop"
                              >
                                {' '}
                                Köp Nu
                              </DefaultButton>
                            </Link>
                          ) : null}
                        </div>
                      </div>
                    )}
                  </div>
                </Grid>
              </>
            </Grid>
          </BackgroundImage>
        );
      })}
    </Carousel>
  );
};

HeroSlider.propTypes = {
  data: array,
};

export default HeroSlider;
