import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby';
import { shape, func } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'gatsby-plugin-intl';
import Header from '../Header';
import { Typography, Grid } from '@material-ui/core';
import COLORS from '../../constants/colors';
import FONTS from '../../constants/fonts';

//TODO:Check if there is better way to get text array directly from intl
const AMOUNT_TEXTS = 4;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    minHeight: '80vh',
    display: 'flex',
    '&::before,&::after': {
      backgroundPosition: 'left top !important',
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: '50vh',
      '&::before,&::after': {
        backgroundPosition: '15% top !important',
      },
    },
    [theme.breakpoints.down('xs')]: {
      '&::before,&::after': {
        backgroundPosition: '25% top !important',
      },
    },
  },
  gridContainer: {
    height: '100%',
    alignSelf: 'center',
    paddingTop: 60,
    paddingBottom: 60,
  },
  gridItem: {
    padding: '14px 0',
    [theme.breakpoints.down('md')]: {
      padding: '10px 0',
      paddingRight: 5,
    },
    [theme.breakpoints.down('xs')]: {
      padding: '5px 0',
      paddingRight: 1,
    },
  },
  title: {
    display: 'inline',
    fontStyle: 'italic',
    fontSize: FONTS.SIZE.XXM,
    [theme.breakpoints.down('md')]: {
      fontSize: FONTS.SIZE.XM,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: FONTS.SIZE.XS,
    },
  },
  description: {
    maxWidth: '65%',
    paddingLeft: 35,
    color: COLORS.WHITE,
    fontSize: FONTS.SIZE.XXS,
    fontWeight: 300,
    [theme.breakpoints.down('md')]: {
      maxWidth: '95%',
      fontSize: FONTS.SIZE.XS,
      paddingLeft: 28,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: FONTS.SIZE.XXS,
      paddingLeft: 18,
    },
    [theme.breakpoints.down(375)]: {
      fontSize: FONTS.SIZE.XXXS,
    },
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  hash: {
    marginRight: 5,
    color: COLORS.RED,
    fontFamily: `${FONTS.FAMILY.SECONDARY} !important`,
  },
}));

const HeroCard = ({ intl }) => {
  const classes = useStyles();
  const { formatMessage } = intl;

  const fluid = useStaticQuery(gqlQuery).allFile.edges[0]
    .node.childImageSharp.fluid;

  const getTexts = () => {
    const texts = [];
    for (let i = 0; i < AMOUNT_TEXTS; i++) {
      const title = `HeroCard.texts.${i}.title`;
      const description = `HeroCard.texts.${i}.description`;
      texts.push({
        title: formatMessage({ id: title }),
        description: formatMessage({ id: description }),
      });
    }
    return texts;
  };

  return (
    <BackgroundImage
      fluid={fluid}
      className={classes.root}
      objectFit
    >
      <Grid container className={classes.gridContainer}>
        <Grid item xs={6} md={7} lg={7} />
        <Grid item xs={6} md={5} lg={5}>
          {getTexts().map(({ title, description }) => (
            <Grid
              item
              key={title}
              className={classes.gridItem}
              xs={12}
            >
              <div className={classes.headerContainer}>
                <Header className={classes.hash}>#</Header>
                <Header className={classes.title}>
                  {title}
                </Header>
              </div>
              <Typography className={classes.description}>
                {description}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </BackgroundImage>
  );
};

export default injectIntl(HeroCard);

HeroCard.propTypes = {
  intl: shape({ formatMessage: func.isRequired })
    .isRequired,
};

export const gqlQuery = graphql`
  query HeroCardQuery {
    allFile(
      filter: {
        dir: { regex: "/src/images/" }
        name: { eq: "hero-card2" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(
              maxHeight: 887
              maxWidth: 2045
              quality: 100
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
